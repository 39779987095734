<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl">Printer Logs</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <div class="grid grid-cols-1 mb-8">
        <div class="m-auto"  v-if="errorFetching">
          <ErrorCard v-if="errorFetching" message="Printer Logs Not Available"/>
        </div>
        <div v-else>
          <div class=" w-full">
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                :totalRows="getTotalElements"
                :isLoading="isLoading"
                max-height="750px"
                theme="polar-bear"
                :sort-options="{
                enabled: false,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: this.serverParams.size,
                  perPageDropdownEnabled: false,
                  dropdownAllowAll: false,
                  setCurrentPage: printerLogs.number + 1
                }"
                :rows="printerLogs.content"
                :columns="columns"
                styleClass="vgt-table bordered"
                row-style-class="printer-log-row"
            >
              <template slot="table-row" slot-scope="props">
                <span :class="[props.column.field === 'createDateTime' && 'whitespace-nowrap', 'text-sm']">
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
              <template slot="table-column" slot-scope="props">
                 <span :class="[props.column.type === 'number' ? 'justify-end' : 'justify-start', 'flex text-orange  block p-0']">
                {{props.column.label}}
                 </span>
              </template>
              <template slot="loadingContent">
                <LoadingCard/>
              </template>
              <div slot="emptystate">
                No Printer Logs to show
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import LocalLogFormatters from "../../mixin/LocalLogFormatters";


export default {
  name: "PrinterLogs",
  mixins: [LocalLogFormatters],
  components: { BasicNav, ErrorCard , PageTemplate, VueGoodTable, LoadingCard},
  data(){
    return {
      errorFetching: false,
      isLoading: true,
      columns: [
        {
          label: 'Event',
          field: 'printType',
          formatFn: this.formatLogType,
          type: String,
          thClass: 'printer-log-header'
        },
        {
          label: 'Status',
          field: 'failed',
          type: 'String',
          formatFn: this.formatSuccessfulPrint,
          thClass: 'printer-log-header'
        },
        {
          label: 'User',
          field: 'username',
          formatFn: this.formatLogUser,
          type: String,
          thClass: 'printer-log-header'
        },
        {
          label: 'Date/Time',
          field: 'createDateTime',
          formatFn: this.formatLogDate,
          type: 'string',
          thClass: 'printer-log-header'
        },
        {
          label: 'Description',
          field: 'description',
          type: String,
          thClass: 'printer-log-header'
        },
        {
          label: 'Expected',
          field: 'totalCount',
          type: 'number',
          thClass: 'printer-log-header'
        },
        {
          label: 'Printed',
          field: 'successCount',
          type: 'number',
          thClass: 'printer-log-header'
        },
        {
          label: 'Failed',
          field: 'failedCount',
          type: 'number',
          thClass: 'printer-log-header'
        },
      ],
      serverParams: {
        page: 0,
        size: 10,
      }
    };
  },
  computed:{
    ...mapGetters('GeneralSettings', ['kioskDetails']),
    ...mapState('Printer',['printerLogs']),

    setCurrentPage(){
      return this.printerLogs.number + 1
    },
    getTotalElements(){
      return this.printerLogs?.totalElements
    }

  },
  methods: {
    ...mapActions('Printer', ['fetchPrinterLogs']),
    updateParams(newProps) {
      this.serverParams = {...this.serverParams, ...newProps};
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage -1});
      this.loadItems();
    },
    async loadItems() {
      this.isLoading = true;
      try{
        await this.fetchPrinterLogs(this.serverParams);
      }catch {
        this.errorFetching = true;
      }finally {
        this.isLoading = false;
      }

    }
  },
  mounted() {
    this.loadItems();
  }

}
</script>
<style>
.chevron.right::after {
  border-left-color: rgba(245, 120, 18, 1) !important;
}
.chevron.left::after {
  border-right-color: rgba(245, 120, 18, 1) !important;
}
</style>